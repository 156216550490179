<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="elevation-x py-3">
          <v-card-title class="pt-0 font-alt">Liste des questionnaires</v-card-title>
          <template v-for="(menuItem, index) in menuSurvey">
            <v-list-item :key="`survey_${index}`" two-line link :to="{name: menuItem.link}">
              <v-list-item-avatar size="48">
                <font-awesome-icon :icon="['fad', menuItem.icon]" size="2x" class="primary--text" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-light">{{ menuItem.title }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption font-alt">{{ menuItem.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SurveySection',
    data() {
      return {
        loading: true,
        menuSurvey: [
          {
            title: 'Questionnaires en cours',
            subtitle: 'Voir les questionnaires en cours',
            icon: 'folder-open',
            link: 'surveys-pending'
          },
          {
            title: 'Questionnaires traités',
            subtitle: 'Voir les questionnaires acquittés ou archivés',
            icon: 'archive',
            link: 'surveys-acquitted'
          }
        ]
      }
    }
  }
</script>
